import React from 'react';
import { AttendanceGraph } from 'cufc-ui'
import { useEffect, useState } from 'react'
import { getDailyAttendanceAggregateAPI } from '../../services/api'
import { DailyAttendanceAggregateCount } from '../../models/AttendanceAggregate'
import { useAuth0 } from '@auth0/auth0-react'

const AdminAttendance: React.FC = () => {
  const [dailyAttendanceAggregateData, setDailyAttendanceAggregateData] =
    useState<DailyAttendanceAggregateCount[]>([])
  const { getAccessTokenSilently } = useAuth0()

  const getDailyAggregateAttendanceData = async () => {
    try {
      const accessToken = await getAccessTokenSilently()
      const res = await getDailyAttendanceAggregateAPI(accessToken)
      if (res.data) {
        setDailyAttendanceAggregateData(res.data)
      }
    } catch (error) {
      console.error('Error fetching attendance:', error)
    }
  }  

  useEffect(() => {
    getDailyAggregateAttendanceData()
  }, [])

  return (
    <div className="bg-white rounded-lg shadow-sm p-4 mt-4">
      <AttendanceGraph data={dailyAttendanceAggregateData} />
    </div>
  );
};

export default AdminAttendance;
