import React, { useState } from 'react';
import {
    Tournament,
    AdditionalResource,
    SelectedEvent
} from './components/types';
import { TournamentHeader } from './components/TournamentHeader';
import { LocationSection } from './components/LocationSection';
import { AdditionalResourcesSection } from './components/AdditionalResourcesSection';
import { PersonalInformationForm } from './components/PersonalInformationForm';
import { GuardianInformationForm } from './components/GuardianInformationForm';
import { EventSelectionForm } from './components/EventSelectionForm';
import { PriceBreakdown } from './components/PriceBreakdown';
import LoadingPopup from '../../components/LoadingPopup';
import { ClubAffiliation } from './components/types';

interface TournamentRegistrationProps {
    tournament: Tournament;
    clubs: ClubAffiliation[];
    onSubmit: (formData: FormData) => Promise<void>;
    onEventsChange: (selectedEvents: SelectedEvent[]) => void;
    onMembershipChange: (formData: FormData) => void;
    bannerImage?: string;
    isLoading?: boolean;
    additionalResources?: AdditionalResource[];
    priceBreakdown: any;
    selectedEvents: SelectedEvent[];
    hasMemberDiscounts: boolean;
    isDisabled?: boolean;
}

interface FormData {
    preferredFirstName: string;
    preferredLastName: string;
    legalFirstName: string;
    legalLastName: string;
    email: string;
    phoneNumber: string;
    clubAffiliation: ClubAffiliation | null;
    selectedEvents: SelectedEvent[];
    isGuardian: boolean;
    guardianFirstName: string;
    guardianLastName: string;
    isCUFCMember: boolean;
}

type FormErrors = {
    [key: string]: string | undefined;
}

const TournamentRegistration: React.FC<TournamentRegistrationProps> = ({
    tournament,
    clubs,
    onSubmit,
    onEventsChange,
    onMembershipChange,
    bannerImage,
    isLoading = false,
    additionalResources,
    priceBreakdown,
    selectedEvents,
    hasMemberDiscounts,
    isDisabled = false,
}) => {
    const [formData, setFormData] = useState<FormData>({
        preferredFirstName: '',
        preferredLastName: '',
        legalFirstName: '',
        legalLastName: '',
        email: '',
        phoneNumber: '',
        clubAffiliation: null,
        selectedEvents,
        isGuardian: false,
        guardianFirstName: '',
        guardianLastName: '',
        isCUFCMember: false,
    });

    const [errors, setErrors] = useState<FormErrors>({});
    const [touched, setTouched] = useState<Record<string, boolean>>({});
    const [isLoadingState, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        const form = e.currentTarget;
        
        // Mark all fields as touched when submitting
        const formElements = Array.from(form.elements) as HTMLInputElement[];
        const newErrors: FormErrors = {};
        
        formElements.forEach(element => {
            if (element.name) {
                setTouched(prev => ({ ...prev, [element.name]: true }));
                
                // Check validity of each field
                if (!element.checkValidity()) {
                    newErrors[element.name] = element.validationMessage || `${element.name} is required`;
                }
                
                // Additional custom validation for specific fields
                if (element.name === 'email' && element.value && !/\S+@\S+\.\S+/.test(element.value)) {
                    newErrors[element.name] = 'Please enter a valid email address';
                }
            }
        });

        // Add validation for guardian fields if isGuardian is checked
        if (formData.isGuardian) {
            if (!formData.guardianFirstName) {
                newErrors['guardianFirstName'] = 'Guardian first name is required';
            }
            if (!formData.guardianLastName) {
                newErrors['guardianLastName'] = 'Guardian last name is required';
            }
        }

        // Add validation for selected events
        if (selectedEvents.length === 0) {
            newErrors['selectedEvents'] = 'Please select at least one event';
        }

        setErrors(newErrors);
        
        if (Object.keys(newErrors).length > 0) {
            return;
        }

        setIsLoading(true);
        try {
            await onSubmit({
                ...formData,
                selectedEvents,
                isCUFCMember: formData.isCUFCMember || false,
                isGuardian: formData.isGuardian || false,
            });
        } catch (error) {
            console.error('Error submitting form:', error);
            setError('Failed to submit registration');
        } finally {
            setIsLoading(false);
        }
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = e.target;
        
        setFormData(prev => {
            let newValue;
            if (type === 'checkbox') {
                newValue = checked;
            } else {
                newValue = value;
            }
            
            const newFormData = { ...prev, [name]: newValue };
            
            if (name === 'isCUFCMember') {
                onMembershipChange(newFormData);
            }
            
            if (name === 'isGuardian' && !checked) {
                return {
                    ...newFormData,
                    guardianFirstName: '',
                    guardianLastName: ''
                };
            }
            
            return newFormData;
        });

        if (touched[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: ''
            }));
        }
    };

    const handleFormChange = (newData: FormData) => {
        setFormData(newData);
        if (newData.isCUFCMember !== formData.isCUFCMember) {
            onMembershipChange(newData);
        }
    };

    const handleEventSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { checked, name: eventId } = e.target;
        const eventName = tournament.events.find(e => e._id === eventId)?.name || '';

        const updatedEvents = checked
            ? [...selectedEvents, { id: eventId, name: eventName }]
            : selectedEvents.filter(e => e.id !== eventId);

        setFormData(prev => ({ ...prev, selectedEvents: updatedEvents }));
        onEventsChange(updatedEvents);
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setTouched(prev => ({ ...prev, [name]: true }));
    };

    const isTournamentEnded = new Date(tournament.endDate) < new Date();

    return (
        <>
            {isLoadingState && <LoadingPopup isOpen={isLoadingState} message="Processing..." />}
            {error && <div>{error}</div>}

            <TournamentHeader
                tournament={tournament}
                bannerImage={bannerImage}
            />
            
            <div className="max-w-4xl mx-auto p-4 md:p-6">
                <LocationSection location={tournament.location} />
                
                {additionalResources && (
                    <AdditionalResourcesSection resources={additionalResources} />
                )}
                
                <form onSubmit={handleSubmit} className="space-y-8">
                    <EventSelectionForm
                        tournament={tournament}
                        selectedEvents={selectedEvents}
                        onEventSelection={handleEventSelection}
                        disabled={isTournamentEnded}
                        errors={{ selectedEvents: errors.selectedEvents }}
                        touched={{ selectedEvents: touched.selectedEvents }}
                    />
                    
                    {!isTournamentEnded && (
                        <>
                            <PersonalInformationForm
                                formData={formData}
                                clubs={clubs}
                                onChange={handleInputChange}
                                onBlur={handleBlur}
                                hasMemberDiscounts={hasMemberDiscounts}
                                required={true}
                                errors={errors}
                                touched={touched}
                                onFormChange={handleFormChange}
                            />

                            {formData.isGuardian && (
                                <GuardianInformationForm
                                    formData={formData}
                                    onChange={handleInputChange}
                                    onBlur={handleBlur}
                                    required={true}
                                    errors={errors}
                                    touched={touched}
                                />
                            )}

                            <PriceBreakdown
                                basePrice={tournament.basePrice}
                                eventPrices={selectedEvents.map(event => ({
                                    eventId: event.id,
                                    name: event.name,
                                    price: tournament.events.find(e => e._id === event.id)?.price || 0
                                }))}
                                appliedDiscounts={priceBreakdown.appliedDiscounts}
                                totalDiscount={priceBreakdown.totalDiscount}
                                finalPrice={priceBreakdown.finalPrice}
                            />

                            <div className="flex justify-end">
                                <button
                                    type="submit"
                                    disabled={isLoadingState || Object.keys(errors).length > 0}
                                    className={`px-6 py-2 text-white font-medium rounded-md ${
                                        isLoadingState || Object.keys(errors).length > 0
                                            ? 'bg-gray-400 cursor-not-allowed'
                                            : 'bg-DeepRed hover:bg-red-700'
                                    }`}
                                >
                                    {isLoadingState ? 'Submitting...' : 'Register'}
                                </button>
                            </div>
                        </>
                    )}
                </form>
            </div>
        </>
    );
};

export default TournamentRegistration;
