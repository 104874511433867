import { MemberModel } from '../../models/Member'
import React, { useEffect, useState, useCallback } from 'react'
import { checkInMemberAPI, getMembersForAttendance } from '../../services/api'
import { CheckInPage } from 'cufc-ui'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import swordShwingSound from '../../assets/merrick079sword-sound-1.wav'

const CheckInView = () => {
  const { getAccessTokenSilently } = useAuth0()
  const navigate = useNavigate()
  const [members, setMembers] = useState<MemberModel[]>([])

  const checkInAudio = new Audio(swordShwingSound)

  const handleOnNavigationClick = (endpoint: string) => {
    navigate('/' + endpoint)
  }

  const getMembers = useCallback(
    async (setMembers) => {
      const accessToken = await getAccessTokenSilently()
      const res = await getMembersForAttendance(accessToken)
      if (res.data) {
        setMembers(res.data)
      }
      console.log(res.data)
    },
    [getAccessTokenSilently],
  )

  const handleCheckIn = async (_id: string) => {
    const accessToken = await getAccessTokenSilently()
    await checkInMemberAPI(_id?.toString(), accessToken)

    //get the members collection, then update the checkedIn property of the member where the id matches
    setMembers((prevMembers) =>
      prevMembers.map((member) =>
        member._id && member._id.toString() === _id
          ? { ...member, checkedIn: !member.checkedIn }
          : member,
      ),
    )
    await checkInAudio.play()
  }

  useEffect(() => {
    const fetchData = async () => {
      await getMembers(setMembers)
    }
    fetchData()
  }, [getMembers])

  return (
    <div className="mx-4 sm:mx-8 md:mx-16 lg:mx-40">
      <CheckInPage
        members={members}
        onNavigationClick={handleOnNavigationClick}
        onCheckIn={handleCheckIn}
      />
    </div>
  )
}

export default CheckInView
