import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const RegistrationSuccessView: React.FC = () => {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        // Get parameters from URL
        const tournamentId = searchParams.get('tournamentId');
        const paymentId = searchParams.get('paymentId');
        const tournamentUrl = searchParams.get('tournamentUrl') || 'https://www.meyersquared.com/';

        // Log parameters for future feature implementation
        console.log('Registration Success Parameters:', {
            tournamentId,
            paymentId,
            tournamentUrl
        });

        // Redirect to tournament software after a brief delay
        setTimeout(() => {
            window.location.href = tournamentUrl;
        }, 2000);
    }, [searchParams]);

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-50 p-4 sm:p-6 lg:p-8">
            <div className="w-full max-w-md mx-auto space-y-6 bg-white rounded-lg shadow-md p-6 sm:p-8">
                <div className="text-center">
                    <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mb-4">
                        <svg className="h-8 w-8 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                        </svg>
                    </div>
                    <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-2">
                        Thank you for registering!
                    </h2>
                    <p className="text-sm sm:text-base text-gray-600">
                         Redirecting you to Meyer Squared...
                    </p>
                </div>
            </div>
        </div>
    );
};

export default RegistrationSuccessView;
