import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { getPublicTournamentsAPI } from '../../services/api';
import { format } from 'date-fns';
import { SmallHero } from 'cufc-ui';

interface Event {
  _id: string;
  name: string;
  description: string;
  startDate: string;
  endDate: string;
  basePrice: number;
  location: string;
  events: Array<{
    name: string;
    description: string;
    startTime: string;
    registrationCap: number;
    price: number;
    registrants: Array<{
      preferredFirstName: string;
      preferredLastName: string;
    }>;
    _id: string;
  }>;
}

const EventsView: React.FC = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await getPublicTournamentsAPI();
        if (response.error) {
          setError(response.error.message);
        } else {
          setEvents(response.data);
        }
      } catch (error) {
        setError('Failed to fetch events');
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const formatEventDate = (date: string) => {
    return format(new Date(date), 'MMM d, yyyy');
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <div className="animate-pulse space-y-4">
            <div className="h-8 w-48 bg-gray-200 rounded mx-auto"></div>
            <div className="h-4 w-full bg-gray-200 rounded"></div>
            <div className="h-4 w-full bg-gray-200 rounded"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto text-center">
          <div className="bg-red-50 border border-red-200 rounded-lg p-4">
            <p className="text-red-600">Error: {error}</p>
          </div>
        </div>
      </div>
    );
  }

  const upcomingEvents = events.filter(
    (event) => new Date(event.startDate) > new Date()
  );
  const pastEvents = events.filter(
    (event) => new Date(event.startDate) <= new Date()
  );

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <SmallHero pageTitle="Events" />

      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {upcomingEvents.length > 0 && (
          <div className="mb-16">
            <h2 className="text-2xl font-bold text-Navy mb-6">Upcoming Events</h2>
            <div className="space-y-2">
              {upcomingEvents.map((event) => (
                <Link
                  key={event._id}
                  to={`/tournament/${event._id}`}
                  className="group relative block"
                >
                  <div className="bg-white rounded-lg p-4 transform transition duration-200 ease-out group-hover:translate-y-[-2px] group-hover:shadow-lg border border-transparent group-hover:border-LightGray">
                    <div className="flex justify-between items-start">
                      <div className="flex-1">
                        <div className="flex justify-between items-center">
                          <h3 className="text-lg font-semibold text-TextBlack group-hover:text-DeepRed">
                            {event.name}
                          </h3>
                          <svg className="h-5 w-5 text-MediumPink group-hover:text-DeepRed transform transition-transform duration-200 group-hover:translate-x-1 flex-shrink-0 ml-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                          </svg>
                        </div>
                        <div className="mt-1.5 flex items-center text-sm text-DarkGray">
                          <svg className="flex-shrink-0 mr-1.5 h-4 w-4 text-MediumPink" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                          </svg>
                          <span>{formatEventDate(event.startDate)}</span>
                        </div>
                        <p className="mt-1 text-sm font-medium text-DeepRed">
                          {event.events.length} events
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}

        {pastEvents.length > 0 && (
          <div>
            <h2 className="text-2xl font-bold text-Navy mb-6">Past Events</h2>
            <div className="space-y-2">
              {pastEvents.map((event) => (
                <Link
                  key={event._id}
                  to={`/tournament/${event._id}`}
                  className="group relative block"
                >
                  <div className="bg-white rounded-lg p-4 transform transition duration-200 ease-out group-hover:translate-y-[-2px] group-hover:shadow-lg border border-transparent group-hover:border-LightGray">
                    <div className="flex justify-between items-start">
                      <div className="flex-1">
                        <div className="flex justify-between items-center">
                          <h3 className="text-lg font-semibold text-TextBlack group-hover:text-DeepRed">
                            {event.name}
                          </h3>
                          <svg className="h-5 w-5 text-MediumPink group-hover:text-DeepRed transform transition-transform duration-200 group-hover:translate-x-1 flex-shrink-0 ml-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                          </svg>
                        </div>
                        <div className="mt-1.5 flex items-center text-sm text-DarkGray">
                          <svg className="flex-shrink-0 mr-1.5 h-4 w-4 text-MediumPink" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                          </svg>
                          <span>{formatEventDate(event.startDate)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventsView;
