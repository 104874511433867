import React, { useEffect, useState } from 'react'
import { CTA, Hero, Intro, NotificationSignup, NuggetCTA, UpcomingStartDates } from 'cufc-ui'
import ContactComponent from '../contact/ContactComponent'
import GoogleMapComponent from '../../components/GoogleMapComponent'
import ScheduleComponent from '../../components/ScheduleComponent'
import { addEmailToPromotionalList, getUpcomingStartDatesAPI } from '../../services/api'
import { UpcomingStartDate } from '../../models/ScheduleTypes'
import { useAuth0 } from '@auth0/auth0-react'

const HomeView = ({ onNavigationClick }: { onNavigationClick: (page: string) => void }) => {
  const [upcomingStartDates, setUpcomingStartDates] = useState<UpcomingStartDate[]>([])
  const [userPermissions, setUserPermissions] = useState<string[]>([])
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0()

  useEffect(() => {
    const fetchUpcomingStartDates = async () => {
      try {
        const data = await getUpcomingStartDatesAPI()
        if (data) {
          setUpcomingStartDates(data)
        }
      } catch (error) {
        console.error('Error fetching schedule data:', error)
      }
    }

    if (isAuthenticated && user) {
      setUserInfo()
    }

    fetchUpcomingStartDates()
  }, [isAuthenticated, user])

  const setUserInfo = async () => {
    try {
      const token = await getAccessTokenSilently()
      const tokenPayload = JSON.parse(atob(token.split('.')[1]))
      const permissions = tokenPayload.permissions || []
      setUserPermissions(permissions)
    } catch (error) {
      console.error('Error fetching user info:', error)
    }
  }

  const handleEmailSignup = async (email: string) => {
    try {
      await addEmailToPromotionalList(email)
    } catch (error) {
      console.error('Error adding email to promotional list:', error)
    }
  }

  return (
    <div className="bg-white">
      <Hero onNavigationClick={onNavigationClick} />
      <NuggetCTA onNavigationClick={onNavigationClick} />
      <Intro onNavigationClick={onNavigationClick} />
      <ScheduleComponent isAdmin={userPermissions.includes('club-admin')} />
      <UpcomingStartDates onNavigationClick={onNavigationClick} upcomingDates={upcomingStartDates} />
      <NotificationSignup onSubmit={handleEmailSignup} />
      <ContactComponent />
      <GoogleMapComponent />
      <CTA onNavigationClick={onNavigationClick} />
    </div>
  )
}

export default HomeView
