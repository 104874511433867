import React, { useState, useEffect } from 'react';
import TournamentRegistration from './TournamentRegistration';
import { calculatePrice } from './utils/calculatePrice';
import { Tournament, PriceBreakdown, SelectedEvent, FormData, ClubAffiliation } from './components/types';
import { getPublicTournamentInfoByIdAPI, submitTournamentRegistrationAPI, getM2ClubsAPI } from '../../services/api';
import { useParams } from 'react-router-dom';

const apiURL = process.env.REACT_APP_API_SERVER_URL;

const TournamentRegistrationView: React.FC = () => {
    const { tournamentId } = useParams<{ tournamentId: string }>();
    
    // API and data state
    const [tournament, setTournament] = useState<Tournament | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [clubs, setClubs] = useState<ClubAffiliation[]>([]);  

    // Registration state
    const [isCUFCMember, setIsCUFCMember] = useState(false);
    const [selectedEvents, setSelectedEvents] = useState<SelectedEvent[]>([]);
    const [priceBreakdown, setPriceBreakdown] = useState<PriceBreakdown>({
        basePrice: 0,
        eventPrices: [],
        appliedDiscounts: [],
        totalDiscount: 0,
        finalPrice: 0
    });

    // Fetch tournament data and clubs
    useEffect(() => {
        const fetchData = async () => {
            if (!tournamentId) {
                setError('No tournament ID provided');
                return;
            }

            setIsLoading(true);
            try {
                // Fetch tournament data
                const tournamentResponse = await getPublicTournamentInfoByIdAPI(tournamentId);
                if (tournamentResponse.error) {
                    throw new Error(tournamentResponse.error.message);
                }
                setTournament(tournamentResponse.data);

                // Fetch clubs
                try {
                    const clubsData = await getM2ClubsAPI();
                    // Ensure we're setting an array
                    setClubs(Array.isArray(clubsData) ? clubsData : []);
                } catch (error) {
                    console.error('Error fetching clubs:', error);
                    setClubs([]); // Reset to empty array on error
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error instanceof Error ? error.message : 'Failed to fetch data');
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [tournamentId]);

    // Update price calculation when relevant state changes
    useEffect(() => {
        if (tournament) {
            setPriceBreakdown(calculatePrice(tournament, selectedEvents, isCUFCMember));
        }
    }, [tournament, selectedEvents, isCUFCMember]);

    // API submission handler
    const handleSubmit = async (formData: FormData) => {
        setIsLoading(true);
        try {
            if (!tournament) return;

            const registrationData = {
                ...formData,
                tournamentName: tournament.name,
                priceBreakdown
            };

            const response = await submitTournamentRegistrationAPI(tournament._id, registrationData);
            const paymentId = response.data.paymentId;
            await createPaymentLink(selectedEvents, priceBreakdown.finalPrice, paymentId);
        } catch (error) {
            console.error('Error submitting registration:', error);
            setError('Failed to submit registration');
        } finally {
            setIsLoading(false);
        }
    };

    // Payment link creation
    const createPaymentLink = async (selectedEvents: SelectedEvent[], finalPrice: number, paymentId: string) => {
        if (!tournament) return;

        const eventNames = tournament.events
            .filter(event => selectedEvents.map(se => se.id).includes(event._id))
            .map(event => event.name);
            
        const name = `${tournament.name} Registration - ${eventNames.join(', ')}`;

        const baseM2Url = 'https://www.meyersquared.com';
        const tournamentUrl = tournament.meyerSquaredTournamentId 
            ? `${baseM2Url}/tournamentdetail/${tournament.meyerSquaredTournamentId}`
            : baseM2Url;
    
        const response = await fetch(`${apiURL}/api/square/createPaymentLink`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                name, 
                amount: Math.round(finalPrice), // Square API will handle conversion to cents
                redirectUrl: `${window.location.origin}/payment/success?tournamentId=${tournamentId}&paymentId=${paymentId}&tournamentUrl=${tournamentUrl}`,
                metadata: {
                    paymentId,
                    tournamentId
                }
            })
        });
    
        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(`Failed to create payment link: ${errorData.error}`);
        }
    
        const paymentData = await response.json();
        window.location.href = paymentData.url;
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!tournament) {
        return <div>Loading...</div>;
    }

    const additionalResources = [
        {
            name: "Tournament Rules",
            link: "https://docs.google.com/document/d/1OCO9HWgbHWwNWjFPBvSD-7wLcVh8c8WnEH6laKAXSx0/edit?tab=t.0#heading=h.q3uegznhj6kz"
        },
        {
            name: "Join our Discord",
            link: "https://discord.gg/KS7trfxb"
        },
        {
            name: "Weapon Requirements",
            link: "https://docs.google.com/spreadsheets/d/1Vf0RzVy3H6ubwBfuJGaX-GV5tf4oMBidSIZLAMEuT-g/edit?usp=sharing"
        }
    ];

    return (
        <div className="container mx-auto px-4 py-8">
            <TournamentRegistration
                tournament={tournament}
                clubs={clubs}
                onSubmit={handleSubmit}
                onEventsChange={setSelectedEvents}
                onMembershipChange={(formData: FormData) => setIsCUFCMember(formData.isCUFCMember)}
                bannerImage={tournament.bannerImage ? `${apiURL}${tournament.bannerImage}` : undefined}
                isLoading={isLoading}
                additionalResources={additionalResources}
                priceBreakdown={priceBreakdown}
                selectedEvents={selectedEvents}
                hasMemberDiscounts={(tournament.discountRules || []).some(rule => rule.memberOnly === true)}
            />
        </div>
    );
};

export default TournamentRegistrationView;
