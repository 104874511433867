import React from 'react'
import { UnsubscribeForm } from 'cufc-ui'
import { removeEmailFromPromotionalList } from '../../services/api'

const UnsubscribeView = () => {
  const handleUnsubscribe = async (email: string) => {
    try {
      await removeEmailFromPromotionalList(email)
    } catch (error) {
      console.error('Error removing email from promotional list:', error)
    }
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <UnsubscribeForm onSubmit={handleUnsubscribe} />
    </div>
  )
}

export default UnsubscribeView
