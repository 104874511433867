import React, { useState, useEffect } from 'react';
import { FormData, FormErrors, ClubAffiliation } from './types';

interface PersonalInformationFormProps {
    formData: FormData;
    errors: FormErrors;
    touched: Record<string, boolean>;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
    clubs: ClubAffiliation[];
    onFormChange: (data: FormData) => void;
    hasMemberDiscounts: boolean;
    required?: boolean;
}

export const PersonalInformationForm: React.FC<PersonalInformationFormProps> = ({
    formData,
    errors,
    touched,
    onChange,
    onBlur,
    clubs,
    onFormChange,
    hasMemberDiscounts,
    required = false,
}) => {
    const [clubSearchText, setClubSearchText] = useState(formData.clubAffiliation?.name || '');

    const handleClubSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchText = e.target.value;
        setClubSearchText(searchText);
        
        // If exact match found, update the club affiliation
        const selectedClub = clubs?.find(club => club.name.toLowerCase() === searchText.toLowerCase());
        const updatedFormData = {
            ...formData,
            clubAffiliation: selectedClub || null
        };
        onFormChange(updatedFormData);
    };

    // Reset search text when selected club changes
    useEffect(() => {
        if (formData.clubAffiliation) {
            setClubSearchText(formData.clubAffiliation.name);
        }
    }, [formData.clubAffiliation]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, type } = e.target;
        if (name === 'clubAffiliation') {
            handleClubSearch(e as React.ChangeEvent<HTMLInputElement>);
        } else {
            const newValue = type === 'checkbox' ? (e.target as HTMLInputElement).checked : (e.target as HTMLInputElement).value;
            const updatedFormData = {
                ...formData,
                [name]: newValue
            };
            onFormChange(updatedFormData);
            
            if (name === 'isCUFCMember') {
                onFormChange(updatedFormData);
            }
        }
    };

    return (
        <div className="mb-8">
            <h2 className="text-xl font-semibold mb-4">Personal Information</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Preferred First Name {required && <span className="text-red-500">*</span>}
                    </label>
                    <input
                        type="text"
                        name="preferredFirstName"
                        value={formData.preferredFirstName}
                        onChange={onChange}
                        onBlur={onBlur}
                        required={required}
                        aria-invalid={touched.preferredFirstName && errors.preferredFirstName ? 'true' : 'false'}
                        className={`w-full border rounded-md h-12 px-3 focus:outline-none ${
                            touched.preferredFirstName && errors.preferredFirstName
                                ? 'border-red-500'
                                : 'focus:border-periwinkle'
                        }`}
                    />
                    {touched.preferredFirstName && errors.preferredFirstName && (
                        <p className="mt-1 text-sm text-red-500 bg-red-50 p-2 rounded-md">
                            {errors.preferredFirstName}
                        </p>
                    )}
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Preferred Last Name {required && <span className="text-red-500">*</span>}
                    </label>
                    <input
                        type="text"
                        name="preferredLastName"
                        value={formData.preferredLastName}
                        onChange={onChange}
                        onBlur={onBlur}
                        required={required}
                        aria-invalid={touched.preferredLastName && errors.preferredLastName ? 'true' : 'false'}
                        className={`w-full border rounded-md h-12 px-3 focus:outline-none ${
                            touched.preferredLastName && errors.preferredLastName
                                ? 'border-red-500'
                                : 'focus:border-periwinkle'
                        }`}
                    />
                    {touched.preferredLastName && errors.preferredLastName && (
                        <p className="mt-1 text-sm text-red-500 bg-red-50 p-2 rounded-md">
                            {errors.preferredLastName}
                        </p>
                    )}
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Legal First Name {required && <span className="text-red-500">*</span>}
                    </label>
                    <input
                        type="text"
                        name="legalFirstName"
                        value={formData.legalFirstName}
                        onChange={onChange}
                        onBlur={onBlur}
                        required={required}
                        aria-invalid={touched.legalFirstName && errors.legalFirstName ? 'true' : 'false'}
                        className={`w-full border rounded-md h-12 px-3 focus:outline-none ${
                            touched.legalFirstName && errors.legalFirstName
                                ? 'border-red-500'
                                : 'focus:border-periwinkle'
                        }`}
                    />
                    {touched.legalFirstName && errors.legalFirstName && (
                        <p className="mt-1 text-sm text-red-500 bg-red-50 p-2 rounded-md">
                            {errors.legalFirstName}
                        </p>
                    )}
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Legal Last Name {required && <span className="text-red-500">*</span>}
                    </label>
                    <input
                        type="text"
                        name="legalLastName"
                        value={formData.legalLastName}
                        onChange={onChange}
                        onBlur={onBlur}
                        required={required}
                        aria-invalid={touched.legalLastName && errors.legalLastName ? 'true' : 'false'}
                        className={`w-full border rounded-md h-12 px-3 focus:outline-none ${
                            touched.legalLastName && errors.legalLastName
                                ? 'border-red-500'
                                : 'focus:border-periwinkle'
                        }`}
                    />
                    {touched.legalLastName && errors.legalLastName && (
                        <p className="mt-1 text-sm text-red-500 bg-red-50 p-2 rounded-md">
                            {errors.legalLastName}
                        </p>
                    )}
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Email {required && <span className="text-red-500">*</span>}
                    </label>
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={onChange}
                        onBlur={onBlur}
                        required={required}
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                        title="Please enter a valid email address"
                        aria-invalid={touched.email && errors.email ? 'true' : 'false'}
                        className={`w-full border rounded-md h-12 px-3 focus:outline-none ${
                            touched.email && errors.email
                                ? 'border-red-500'
                                : 'focus:border-periwinkle'
                        }`}
                    />
                    {touched.email && errors.email && (
                        <p className="mt-1 text-sm text-red-500 bg-red-50 p-2 rounded-md">
                            {errors.email}
                        </p>
                    )}
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Phone Number {required && <span className="text-red-500">*</span>}
                    </label>
                    <input
                        type="tel"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        onChange={onChange}
                        onBlur={onBlur}
                        required={required}
                        aria-invalid={touched.phoneNumber && errors.phoneNumber ? 'true' : 'false'}
                        className={`w-full border rounded-md h-12 px-3 focus:outline-none ${
                            touched.phoneNumber && errors.phoneNumber
                                ? 'border-red-500'
                                : 'focus:border-periwinkle'
                        }`}
                    />
                    {touched.phoneNumber && errors.phoneNumber && (
                        <p className="mt-1 text-sm text-red-500 bg-red-50 p-2 rounded-md">
                            {errors.phoneNumber}
                        </p>
                    )}
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                        Club Affiliation
                    </label>
                    <div className="relative">
                        <input
                            list="clubs"
                            type="text"
                            name="clubAffiliation"
                            value={clubSearchText}
                            onChange={handleClubSearch}
                            onBlur={(e) => {
                                // On blur, if the text doesn't match a club exactly, clear it
                                const exactMatch = clubs?.find(club => club.name.toLowerCase() === e.target.value.toLowerCase());
                                if (!exactMatch) {
                                    setClubSearchText('');
                                    const updatedFormData = {
                                        ...formData,
                                        clubAffiliation: null
                                    };
                                    onFormChange(updatedFormData);
                                }
                            }}
                            className="w-full border rounded-md h-12 px-3 focus:outline-none focus:border-periwinkle"
                            placeholder="Search for a club..."
                        />
                        {clubs && clubs.length > 0 && (
                            <datalist id="clubs">
                                {clubs.map(club => (
                                    <option key={club.meyerSquaredClubId} value={club.name} />
                                ))}
                            </datalist>
                        )}
                    </div>
                </div>
            </div>

            <div className="mt-4 space-y-4">
                {hasMemberDiscounts && (
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            id="isCUFCMember"
                            name="isCUFCMember"
                            checked={formData.isCUFCMember}
                            onChange={handleInputChange}
                            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        />
                        <label htmlFor="isCUFCMember" className="ml-2 block text-sm text-gray-700">
                            I am a member of Columbus United Fencing Club
                        </label>
                    </div>
                )}

                <div className="flex items-center">
                    <input
                        type="checkbox"
                        id="isGuardian"
                        name="isGuardian"
                        checked={formData.isGuardian}
                        onChange={handleInputChange}
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    />
                    <label htmlFor="isGuardian" className="ml-2 block text-sm text-gray-700">
                        I am a guardian signing up on behalf of a minor that is at least 14 years of age
                    </label>
                </div>
            </div>
        </div>
    );
};
