import { MemberModel } from '../models/Member'
import { AxiosRequestConfig } from 'axios'
import { callExternalApi } from './external-api.service'
import { SendToListParams, EmailList } from '../models/EmailTypes'
import { 
  ScheduleItem, 
  Closure, 
  Event, 
  UpcomingStartDate, 
  ScheduleData 
} from '../models/ScheduleTypes'
import { Tournament } from '../models/TournamentTypes'
import { ClubAffiliation } from '../views/events/components/types'

// Use localhost in development, production URL otherwise
const apiURL = process.env.NODE_ENV === 'development' 
  ? 'http://localhost:3000'
  : process.env.REACT_APP_API_SERVER_URL

export const getMembersAPI = async (accessToken: string) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/members/getAll`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }
  return await callExternalApi({ config })
}

export const getMembersForAttendance = async (accessToken: string) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/members/getAllForAttendance`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }
  return await callExternalApi({ config })
}

export const getMemberByIdAPI = async (
  id: string | undefined,
  accessToken: string,
) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/members/getOne/${id}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }
  return await callExternalApi({ config })
}

export const addMemberAPI = async (
  member: MemberModel,
  accessToken: string,
) => {
  try {
    const config: AxiosRequestConfig = {
      url: apiURL + `/api/members/add`,
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      data: member,
    }
    return await callExternalApi({ config })
  } catch (error) {
    console.log('Oops ' + error)
  }
}

export const deleteMemberAPI = async (
  memberId: string,
  accessToken: string,
) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/members/delete/${memberId}`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }
  return await callExternalApi({ config })
}

export const updateMemberAPI = async (
  member: MemberModel,
  accessToken: string,
) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/members/update/${member._id}`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: member,
  }

  return await callExternalApi({ config })
}

export const checkInMemberAPI = async (_id: string, accessToken: string) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/attendance/checkIn`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: {
      userId: _id,
    },
  }
  return await callExternalApi({ config })
}

export const getDailyAttendanceAggregateAPI = async (accessToken: string) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/attendance/dailyAggregate`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }
  return await callExternalApi({ config })
}

export const addEmailToPromotionalList = async (email: string) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/email-lists/promotional/emails`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: {
      emails: [email]
    }
  }
  return await callExternalApi({ config })
}

export const removeEmailFromPromotionalList = async (email: string) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/email-lists/promotional/emails`,
    method: 'DELETE',
    headers: {
      'content-type': 'application/json',
    },
    data: {
      emails: [email]
    }
  }
  return await callExternalApi({ config })
}

export const sendEmailToListAPI = async (
  params: SendToListParams,
  accessToken: string,
) => {
  const config: AxiosRequestConfig = {
    url: `${apiURL}/api/email/sendToList`,
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: params,
    timeout: 600000, // 10 minute timeout for email sending
  }
  return await callExternalApi({ config })
}

export const getEmailListsAPI = async (accessToken: string) => {
  const config: AxiosRequestConfig = {
    url: `${apiURL}/api/email-lists/`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }
  return await callExternalApi({ config })
}

export const getScheduleDataAPI = async () => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/schedule`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  }
  return await callExternalApi({ config })
}

export const getUpcomingStartDatesAPI = async (): Promise<UpcomingStartDate[]> => {
  const config: AxiosRequestConfig = {
    url: `${apiURL}/api/schedule/upcoming-start-dates`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
    },
  };

  const { data, error } = await callExternalApi({ config });

  if (error) {
    throw error;
  }

  return data;
};

export const getAllMemberEmailsListAPI = async (accessToken: string) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/email-lists/members/all`,
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }
  return await callExternalApi({ config })
}

// Schedule Items
export const addScheduleItemAPI = async (
  item: ScheduleItem,
  accessToken: string,
) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/schedule/items/add`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: item,
  }
  return await callExternalApi({ config })
}

export const updateScheduleItemAPI = async (
  item: ScheduleItem,
  accessToken: string,
) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/schedule/items/update/${item._id}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: item,
  }
  return await callExternalApi({ config })
}

export const deleteScheduleItemAPI = async (
  itemId: string,
  accessToken: string,
) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/schedule/items/delete/${itemId}`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }
  return await callExternalApi({ config })
}

// Upcoming Events
export const addEventAPI = async (
  event: Event,
  accessToken: string,
) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/schedule/events/add`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: event,
  }
  return await callExternalApi({ config })
}

export const updateEventAPI = async (
  event: Event,
  accessToken: string,
) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/schedule/events/update/${event._id}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: event,
  }
  return await callExternalApi({ config })
}

export const deleteEventAPI = async (
  eventId: string,
  accessToken: string,
) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/schedule/events/delete/${eventId}`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }
  return await callExternalApi({ config })
}

// Upcoming Closures
export const addClosureAPI = async (
  closure: Closure,
  accessToken: string,
) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/schedule/closures/add`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: closure,
  }
  return await callExternalApi({ config })
}

export const updateClosureAPI = async (
  closure: Closure,
  accessToken: string,
) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/schedule/closures/update/${closure._id}`,
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    data: closure,
  }
  return await callExternalApi({ config })
}

export const deleteClosureAPI = async (
  closureId: string,
  accessToken: string,
) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/schedule/closures/delete/${closureId}`,
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  }
  return await callExternalApi({ config })
}

export const getPublicTournamentInfoByIdAPI = async (
  tournamentId: string,
) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/tournaments/public/${tournamentId}`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    },
  }
  return await callExternalApi({ config })
}

export const getPublicTournamentsAPI = async () => {
  const config: AxiosRequestConfig = {
    url: apiURL + '/api/tournaments/public',
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  }
  return await callExternalApi({ config })
}

export const submitTournamentRegistrationAPI = async (
  tournamentId: string,
  formData: any,
) => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/tournaments/${tournamentId}/register`,
    method: 'POST',
    headers: {
      'content-type': 'application/json'
    },
    data: formData
  }
  return await callExternalApi({ config })
}

/**
 * Fetch all clubs from Meyer Squared
 * @returns Promise containing array of clubs with ID and name
 */
export const getM2ClubsAPI = async (): Promise<ClubAffiliation[]> => {
  const config: AxiosRequestConfig = {
    url: apiURL + `/api/m2/clubs`,
    method: 'GET',
    headers: {
      'content-type': 'application/json'
    }
  }
  const response = await callExternalApi({ config })
  return response.data;
}
